<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">

                    <template>
                        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                            <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                                <div class="section-title margin-deliver">{{ $t(`Email Alerts`) }}
                                    <v-popover offset="10" :disabled="false" style="display:initial;">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                        <template slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <div style="text-align:left">
                                                <a v-close-popover
                                                   href="https://easy.jobs/docs/how-to-configure-email-setup-settings"
                                                   target="_blank">
                                                    {{ $t(`How to configure email setup?`) }}
                                                </a>
                                            </div>
                                        </template>
                                    </v-popover>
                                </div>
                            </div>
                            <router-link v-if="hasPackagePermissionRemoteInterview" :to="{ name: 'company.setting.email.smtp' }" class="button primary-button">{{ $t(`SMTP Setup`) }}</router-link>
                        </div>

                    </template>

                    <!-- data table -->
                    <div class="data-table user-table" v-if="isContentLoading">
                        <TableLoader></TableLoader>
                    </div>
                    <div class="card email-setup-card" v-else>
                        <div class="card-body">

                            <h4 v-show="isEmpty" class="empty-message">{{ $t(`No email found`) }}</h4>

                            <div v-show="!isEmpty" class="notification__settings">
                                <div class="settings__item">
                                    <div class="item__content">
                                        <div class="content">
                                            <h5 class="mb-1">Title</h5>
                                        </div>
                                    </div>

                                    <div class="item__switch">
                                        <p>Notify</p>
                                    </div>

                                    <div class="item__action">
                                        <p>Action</p>
                                    </div>
                                </div>
                                <div class="settings__item" v-for="(setting, index) in settings">
                                    <div class="item__content">
                                        <div class="icon">
                                            <i :class="`eicon ${setting.icon}`"></i>
                                        </div>
                                        <div class="content">
                                            <h5 class="mb-1">{{ setting.name }}</h5>
                                            <p>{{ setting.description }}</p>
                                        </div>
                                    </div>

                                    <div class="item__switch">
                                        <input type="checkbox" class="notify"
                                               v-model="setting.isNotify"
                                               value="true"
                                               @change="toggleNotify(setting)"
                                               :id="`switch-${index}`"
                                        />
                                        <label :for="`switch-${index}`">
                                            <span v-if="setting.isNotify">{{ $t('Yes') }}</span>
                                            <span v-else>{{ $t('No') }}</span>
                                        </label>
                                    </div>

                                    <div class="item__action">
                                        <a href="javascript:void (0);" @click.prevent="showUpdateModal(setting)"
                                           class="control-button control-button--primary has_bg_color">
                                            <div class="control-button__icon">
                                                <i class="eicon e-pencil"></i>
                                            </div>
                                            <span>{{ $t(`update`) }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <modal-update-email
                    v-if="updateSetting"
                    @closeUpdateModal="hideUpdateEmailModal"
                    @setupUpdated="setupUpdated"
                    :data='updateData'
            ></modal-update-email>

        </div>
    </dashboard-layout>
</template>

<script>
const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const SettingMenu = () => import( "../../../components/company/SettingSidebar");
const ModalUpdateEmail = () => import("../../../components/modal/company/UpdateEmail");
const TableLoader = () => import("../../../components/_loaders/_TableLoader");

import { mapState} from 'vuex';

import client from "../../../app/api/Client";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
        ModalUpdateEmail,
        TableLoader
    },

    data() {
        return {
            isContentLoading: true,
            settings: [],
            updateData: null,
            updateSetting: false,
        }
    },

    computed: {
        ...mapState(['company', 'user']),

        isEmpty() {
            return this.settings.length === 0;
        },

        hasPackagePermissionRemoteInterview() {
            return this.user.package_rules.remote_interview;
        }
    },
    methods: {

        hideUpdateEmailModal() {
            this.updateSetting = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },

        async getEmailSettings() {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/setting/email');
                this.settings = data;

            } catch (e) {
            }
            this.isContentLoading = false;
        },

        showUpdateModal(setting) {
            this.updateData = setting;
            this.updateSetting = true;
            const el = document.body;
            el.classList.add('modal-open');
        },

        async toggleNotify(setting) {
            try {
                let {data: { message}} = await client().put(`/company/setting/email/${setting.id}/notify`, {
                    notify: setting.isNotify
                });
                this.$toast.success(this.$t(message));
            } catch (error) {
                this.$toast.error(error.response.data.message);
            }
        },

        async setupUpdated() {
            await this.getEmailSettings();
        }
    },
    mounted() {
        this.getEmailSettings();
    }
}
</script>

<style scoped>
    /* Css for notify button */
    input.notify[type=checkbox] {
        display: none;
    }

    input.notify + label {
        cursor: pointer;
        width: 64px;
        height: 30px;
        background: #FF5F74;
        display: inline-block;
        border-radius: 100px;
        position: relative;
        margin: auto;
    }

    input.notify + label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    input.notify:checked + label {
        background: #60CE83;
    }

    input.notify:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

    input.notify + label > span {
        color: #fff;
        text-transform: uppercase;
        float: right;
        padding: 8px 8px;
        font-size: .9rem;
        line-height: 1;
    }

    input.notify:checked + label > span {
        float: left;
    }

    /*Notification Setting*/
    .notification__settings .settings__item {
        display: flex;
    }

    /*@media all and (max-width: 439px) {*/
    /*    .notification__settings .settings__item {*/
    /*        display: block;*/
    /*        overflow: hidden;*/
    /*    }*/
    /*}*/

    .notification__settings .settings__item:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
    }

    .notification__settings .settings__item:not(:first-child) {
        padding-top: 20px;
    }

    .notification__settings .settings__item .item__content {
        flex: 1 1 auto;
        padding-right: 15px;
    }

    /*@media all and (max-width: 439px) {*/
    /*    .notification__settings .settings__item .item__content {*/
    /*        padding-right: 0;*/
    /*        padding-bottom: 15px;*/
    /*    }*/
    /*}*/

    .notification__settings .settings__item .item__content h5 {
        font-size: 15px;
        font-weight: 500;
    }

    .notification__settings .settings__item .item__content h5 i {
        margin-right: 8px;
        font-size: 16px;
    }

    .notification__settings .settings__item .item__content p {
        font-size: 14px;
        color: #7d8091;
        font-weight: 400;
    }

    .notification__settings .settings__item .item__switch {
        flex: 0 0 65px;
        padding-right: 25px;
    }


    .notification__settings .settings__item .item__action {
        flex: 0 0 65px;
        float: left;
    }


    @media all and (max-width: 990px) {
        .feature-image-box > .row {
            margin-bottom: 10px !important;
        }
    }

    @media (max-width: 767.98px) {
        .control-button span, .control-button--primary span {
            display: block;
        }
    }
    .email-setup-card {
        border: 0;
        border-radius: 10px;
    }
    .email-setup-card .card-body {
        padding :30px;
    }
    .item__content {
        display: flex;
        gap: 15px;
    }
    .item__content .icon i {
        /*font-size: 26px;*/
    }
    .notification__settings {
        overflow: auto;
    }
    .notification__settings .settings__item {
        min-width: 600px;
    }
    .item__content .content p {
        max-width: 600px;
    }
    .notification__settings .settings__item .item__action {
        flex: 0 0 110px;
    }
    .notification__settings .settings__item .item__switch {
        flex: 0 0 90px;
    }
    .notification__settings .settings__item .item__action a {
        display: inline-flex;
    }


</style>
